import { gql } from '@apollo/client';
import { gqlClient } from '../gql-client';

export const getLaunchUrl = async (): Promise<{ url: string } | undefined> => {
  try {
    const { data } = await gqlClient.query({
      query: getLaunchUrlQuery,
      fetchPolicy: 'no-cache',
    });

    return {
      url: data.getLaunchUrl.url,
    };
  } catch (error) {
    console.log('Failed to fetch query', { error });
    return undefined;
  }
};

const getLaunchUrlQuery = gql`
  query GetLaunchUrl {
    getLaunchUrl {
      url
    }
  }
`;
