import React, { useEffect, useState, useCallback } from 'react';
import { useIsAppOpenInHub } from '@getvim/components-hooks-vim-connect';
import { Loader, Toast } from '@getvim/atomic-ui';
import { ModuleNames } from '@getvim/vim-app-infra';

import { apiClient } from '../../api';

import './index.less';

const initialState = {
  url: undefined,
  resultsError: false,
  isLoading: false,
};
const { ToastContainer, Slide } = Toast;

export const Widget: React.FC = () => {
  const isAppOpen = useIsAppOpenInHub(ModuleNames.PriviaInsightsWidget);
  const [shouldUpdateLaunchUrl, setShouldUpdateLaunchUrl] = useState<boolean>(true);

  const [launchUrl, setLaunchUrl] = useState<{
    url: string | undefined;
    resultsError: boolean;
    isLoading: boolean;
  }>(initialState);

  const getWidgetLaunchUrl = useCallback(async () => {
    setLaunchUrl({
      url: undefined,
      isLoading: true,
      resultsError: false,
    });

    const response = await apiClient.getLaunchUrl();

    setLaunchUrl({
      url: response?.url,
      isLoading: false,
      resultsError: !response,
    });
    setShouldUpdateLaunchUrl(false);
  }, []);

  /**
   * Trigger launch request at first time
   */
  useEffect(() => {
    if (shouldUpdateLaunchUrl && isAppOpen) {
      getWidgetLaunchUrl();
    }
  }, [getWidgetLaunchUrl, shouldUpdateLaunchUrl, isAppOpen]);

  /**
   * Trigger launch request after reopening the app which contains error
   */
  useEffect(() => {
    if (!isAppOpen && launchUrl.resultsError) {
      setLaunchUrl(initialState);
      setShouldUpdateLaunchUrl(true);
    }
  }, [isAppOpen]);

  const { url, resultsError, isLoading } = launchUrl;

  const onRetry = () => getWidgetLaunchUrl();

  return (
    <>
      <div className="privia-insights-app-container">
        <div className="frame-wrapper">
          {isLoading && <Loader type="dots" className="privia-insights-app-container__loader" />}
          {resultsError && <ErrorLoadIframe onRetry={onRetry} />}
          {url && <iframe title="privia-insights-widget" className="frame" src={url} />}
        </div>
      </div>
      <ToastContainer
        className=""
        position="bottom-right"
        hideProgressBar
        autoClose={3000}
        pauseOnFocusLoss={false}
        transition={Slide}
      />
    </>
  );
};

const ErrorLoadIframe: React.FC<any> = ({ onRetry }: any) => (
  <div className="error-load-iframe">
    <div className="error-load-iframe-title">Something went wrong :(</div>
    <div className="error-load-iframe-text">Please try again</div>
    <img
      className="error-load-iframe-img"
      src="https://static.getvim.com/img/errorPageImg.png"
      alt="loading logo"
    />
    <button type="button" className="error-load-iframe-button" onClick={onRetry}>
      Retry
    </button>
  </div>
);
