import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { VimCommunicationProvider, ModuleNames } from '@getvim/vim-app-infra';
import { themes, ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';
import '@getvim/atomic-ui/assets/styles/main.less';
import { Widget } from './components/widget';

const App: React.FC = () => {
  return (
    <ThemeVariablesWrapper theme={themes.priviaInsights}>
      <Router>
        <Switch>
          <Route path="/privia-insights">
            <VimCommunicationProvider widgetId={ModuleNames.PriviaInsightsWidget}>
              <Widget />
            </VimCommunicationProvider>
          </Route>
        </Switch>
      </Router>
    </ThemeVariablesWrapper>
  );
};

export default App;
