import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { toast, Slide, ToastOptions as ReactToastifyToastOptions } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { ColorNameEnum, SizeEnum, WeightEnum } from '../../types';
import Text from '../../atoms/text';

import 'react-toastify/dist/ReactToastify.min.css';
import './index.less';
import Button from '../../atoms/button';
import { Tooltip } from '../../atoms';

const SELECT_BUTTON_DISABLED_MESSAGE = 'Action disabled by your clinic during the beta period';

export enum ToastActionButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum SingleActionButtonAlignment {
  LEFT = 'align-left',
  RIGHT = 'align-right',
}

interface ToastActionButton {
  buttonType: ToastActionButtonType;
  text: string;
  disabled?: boolean;
  onClick: () => void;
}

export interface ToastProps {
  title: string;
  message: string;
  html?: boolean;
  dismissText?: string;
  vimConnectStyle?: boolean;
  optumStyle?: boolean;
  toastIcon?: any;
  id?: any;
  actionButtons?: ToastActionButton[];
  onDismissButtonClicked?: any;
  onMouseEnter?: () => void;
  onElementCreated?: (element: HTMLDivElement) => () => void;
  singleActionButtonAlignment?: SingleActionButtonAlignment;
  dataTestId?: string;
}

const clearById = (id: string | number) => {
  toast.dismiss(id);
};

const Toast = ({
  title,
  message,
  html,
  dismissText,
  toastIcon,
  actionButtons,
  onDismissButtonClicked,
  singleActionButtonAlignment,
  dataTestId,
  onElementCreated,
}: ToastProps) => {
  const toastRef = useRef(null);

  useEffect(() => {
    if (toastRef.current) {
      return onElementCreated?.(toastRef.current);
    }
  }, [onElementCreated, toastRef]);

  const onDismissTextClick = useCallback(
    (e) => {
      e.stopPropagation();
      onDismissButtonClicked();
    },
    [onDismissButtonClicked],
  );

  return (
    <div className="toast" data-testid={dataTestId} ref={toastRef}>
      <div className="toast-icon">{toastIcon}</div>
      <Text
        className="toast-title"
        size={SizeEnum['18px']}
        colorName={ColorNameEnum.ultraDark}
        weight={WeightEnum.bold}
        text={title}
        html={html}
      />
      <Text
        className="toast-message"
        colorName={ColorNameEnum.ultraDark}
        size={SizeEnum['14px']}
        text={message}
        html={html}
      />
      {dismissText && (
        <Text
          className="margin-top toast-dismiss"
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.semibold}
          size={SizeEnum['14px']}
          text={dismissText}
          onClick={onDismissTextClick}
        />
      )}
      <div
        className={`action-buttons ${singleActionButtonAlignment ?? ''}`}
        data-testid="action-buttons"
      >
        {actionButtons?.map((actionButton, i) => (
          <Tooltip
            key={i}
            placement="top"
            tooltipContent={SELECT_BUTTON_DISABLED_MESSAGE}
            className="select-button-tooltip-disabled"
            bgColor="white"
            hideTooltip={!actionButton.disabled}
          >
            <Button {...actionButton} />
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export type ToastOptions = ToastProps & ReactToastifyToastOptions;
export const ToastTypes = toast.TYPE;

const toastFunctionByType = {
  [toast.TYPE.ERROR]: toast.error,
  [toast.TYPE.DEFAULT]: toast.info,
  [toast.TYPE.INFO]: toast.info,
  [toast.TYPE.SUCCESS]: toast.success,
  [toast.TYPE.WARNING]: toast.warning,
};

export function createToast({
  title,
  message,
  html,
  dismissText,
  vimConnectStyle = false,
  optumStyle = false,
  toastIcon,
  id = { uuid },
  actionButtons,
  onMouseEnter = () => {},
  singleActionButtonAlignment,
  dataTestId,
  onElementCreated,
  ...toastifyOptions
}: ToastOptions) {
  if (toast.isActive(id) && !toastifyOptions.updateId) {
    clearById(id);
  }
  toastFunctionByType[toastifyOptions.type || toast.TYPE.DEFAULT]?.(
    <div onMouseEnter={onMouseEnter}>
      <Toast
        title={title}
        message={message}
        html={html}
        dismissText={dismissText}
        toastIcon={toastIcon}
        actionButtons={actionButtons}
        onDismissButtonClicked={toastifyOptions.onClose}
        singleActionButtonAlignment={singleActionButtonAlignment}
        onElementCreated={onElementCreated}
        dataTestId={dataTestId}
      />
    </div>,
    {
      className: classNames('atomic-toast', toastifyOptions.className, {
        vimConnectStyle,
        optumStyle,
      }),
      transition: Slide,
      closeButton: false,
      toastId: id,
      ...toastifyOptions,
    },
  );
}

const isDisplayed = (toastId) => toast.isActive(toastId);

const clearAll = () => {
  toast.dismiss();
};

export { clearById, clearAll, isDisplayed };

export { Zoom, ToastContainer, Slide } from 'react-toastify';
